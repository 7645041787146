<template>
  <div>
    <v-subheader
      class="pre-header text-subtitle-1 text--primary pt-1"
    >
      <div
        class="docType text-truncate pb-1"
        v-if="mounted && documentTypesMixinReady"
      >
        {{ getDocumentTypeLocalization(document.type) }}
        <svg
          v-if="getDocTypeByDocTypeId(document.type).aiContent"
          class="aiIcon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :d="svgIcons.ai"
            transform="scale(0.025, 0.025)"
          />
        </svg>
      </div>
      <span class="options mt-n1 pb-1">
        <span
          class="pdfToggle"
          v-if="fileFetchDone && pdfViewerAvailable && $vuetify.breakpoint.mdAndUp"
        >
          <span class="txt">TXT</span>
          <v-switch
            class="pdfSwitch"
            small
            color="grey"
            @change="toggleShowPDF"
            :input-value="showPDF"
          ></v-switch>
          <span>PDF</span>
        </span>

        <div class="d-inline-flex justify-end flex-grow-0 flex-shrink-0">
          <span v-if="pdfViewerAvailable && $vuetify.breakpoint.smAndDown">
            <v-btn
              @click="toggle"
              icon
              small
              fab
              color="grey"
            >
              <v-icon v-if="isActivePdf">mdi-file-document</v-icon>
              <v-icon v-else>mdi-file-pdf-box</v-icon>
            </v-btn>
          </span>

          <document-links
            :link="link || document.url"
            :sourceUrl="getPdfSourceUrl()"
            :sourceTitle="document.title"
            :documentId="documentId"
            :date="document.date"
            :type="getDocumentTypeLocalization(document.type)"
            :docType="document.type"
            :listModuleSupport="true"
            :institution="document.institution"
          />
        </div>
      </span>
    </v-subheader>

    <v-card>
      <v-card-title
        v-if="editedTitle"
      >
        {{ editedTitleStripHtml }}
      </v-card-title>

      <v-card-subtitle class="font-weight-medium text--primary pt-2" v-if="document">
        <span class="subTitlePart">{{ $t('detail.original') }}: <a
          v-if="documentUrl()"
          class="blue--text text--darken-2"
          @click="trackOpenSource(documentUrl())"
          :href=documentUrl()
          target="_blank"
        >{{ computedUrlToSourceName }}</a></span>
        <span class="subTitlePart">{{ $t('generic.date' )}}: {{ formatDate(this.document.date) }}</span>
        <span
          v-if="voteResultsPresent"
          class="subTitlePart"
        >
          {{ $t('detail.vote') }}: {{ voteResultsDate }}
        </span>
        <span
          v-if="this.document?.activityDate"
          class="subTitlePart"
        >
          {{ $t('detail.meeting')}}: {{ formatDate(this.document.activityDate) }}
        </span>
        <span
            v-if="this.document.availableOnSourceSince"
            class="subTitlePart"
        >
          {{ $t('detail.uploadedBySource') }}: {{ formatDate(this.document.availableOnSourceSince) }}
        </span>
      </v-card-subtitle>

      <v-card-actions v-if="hasDebugAccess">
        <v-btn color="primary" @click="showMetadataDialog = true">View Metadata</v-btn>
      </v-card-actions>
    </v-card>

    <metadata-dialog
        :document="document"
        v-model="showMetadataDialog"
    />

    <div v-if="fileFetchDone">
      <v-card
        v-if="!showPDF"
        class="my-1"
      >
        <!-- Audio documentText -->
        <v-card-text
          v-if="showAudioText"
        >
          <p
            v-if="document.includeDebateAIDesclaimer"
            class="font-italic"
          >
            {{ $t('debates.transcriptionDisclaimer') }}
          </p>
          <p
            class="audioText"
          >
            <audio-text-fragment
              v-for="(chunk, index) in document.textChunks"
              @playAudioChunk="playAudioChunk"
              :chunk="chunk"
              :key="index"
            />
          </p>
        </v-card-text>

        <!-- Normal documentText -->
        <v-card-text
          v-else-if="document && document.text"
        >
          <document-text
            id="documentText"
            ref="documentText"
            :isRawHTML="!!document.rawDocumentContent"
            :text="document.rawDocumentContent ? document.rawDocumentContent : document.text"
            :link="link || document.url"
            :rssDocument="rssDocument"
          />
        </v-card-text>
      </v-card>

      <!-- PDF.js viewer -->
      <pdf-viewer
        v-if="showPDF && getPdfSourceUrl()"
        :pdf="pdf"
        :keywords="document.keywords"
        @pdfPageReady="reemit"
        @pdfRender="$emit('pdfRender')"
        @pdfRendered="$emit('pdfRendered')"
        :expandedView="pdfExpandedView"
        @toggleExpandedView="$emit('togglePdfExpandedView')"
      ></pdf-viewer>
    </div>
  </div>
</template>

<script>
import useDocumentTypes from '@/composables/documentTypesComposable';

import { mapGetters } from 'vuex';

import { showDialog } from '@/helpers/dialogHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';
import fragDocufy from '@/helpers/fragDocufyHelper';
import { stripHtml } from '@/helpers/toolbelt';

import DocumentLinks from '@/components/DocumentLinks.vue';

import EventBus from '@/event-bus';

import svgIcons from '@/assets/svg_icons';

import PdfViewer from './PDFViewer.vue';
import DocumentSwitchToTextView from './DocumentSwitchToTextView.vue';
import DocumentText from './DocumentText.vue';
import AudioTextFragment from './AudioTextFragment.vue';
import MetadataDialog from './MetadataDialog.vue';

export default {
  components: {
    DocumentLinks,
    DocumentText,
    PdfViewer,
    AudioTextFragment,
    MetadataDialog,
  },

  setup() {
    const {
      documentTypesMixinReady,
      getDocTypeByDocTypeId,
      getLocalizationForDocTypeId,
      findDocumentTypesByLabels,
      modulesMeetDocumentTypeRequirements,
      getPrunedDocumentTypesByModules,
      getAllSupportedEuroparlCodes,
      getAllEuroparlDocumentTypesPerInstitution,
    } = useDocumentTypes();

    return {
      documentTypesMixinReady,
      getDocTypeByDocTypeId,
      getLocalizationForDocTypeId,
      findDocumentTypesByLabels,
      modulesMeetDocumentTypeRequirements,
      getPrunedDocumentTypesByModules,
      getAllSupportedEuroparlCodes,
      getAllEuroparlDocumentTypesPerInstitution,
    };
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    fileFetchDone: {
      type: Boolean,
      required: true,
      default: false,
    },
    rssDocument: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdf: {
      type: ArrayBuffer,
      required: false,
    },
    showPDF: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdfMissing: {
      type: Boolean,
      required: true,
    },
    pdfExpandedView: {
      type: Boolean,
      required: true,
    },
    showAudioText: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      prevElement: null,
      mounted: false,
      audioPlayer: null,
      isActivePdf: true,
      svgIcons,
      showMetadataDialog: false,
    };
  },

  // mounted() { this.mounted = true; },
  mounted() {
    const vm = this;
    // Intercept key 'Ctrl-F' and Cmd-F
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.metaKey && e.keyCode === 70))) {
        if (vm.showPDF && vm.getPdfSourceUrl()) {
          // e.preventDefault();
          vm.initSwitchToDocumentView();
        }
      }
    });
    this.audioPlayer = document.getElementById('audio-to-text');
    this.mounted = true;
  },

  unmounted() {
    const vm = this;
    window.removeEventListener('keydown', (e) => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.metaKey && e.keyCode === 70))) {
        vm.initSwitchToDocumentView();
      }
    });
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      isIE11: 'isIE11',
      userPreferences: 'userPreferences',
    }),

    hasDebugAccess() {
      return this.authHasModuleAccess('MC99');
    },

    computedUrlToSourceName() {
      if (this.document.source) return this.document.source;
      return urlToSourceName(this.document.url).replace('www.', '');
    },

    documentId() {
      return this.$route.params.documentId;
    },

    editedTitle() {
      if (this.document.title && this.document.fragDocu) {
        return `${this.document.title} ${fragDocufy(this.document.fragDocu) || ''}`;
      }
      return this.document.title;
    },

    editedTitleStripHtml() {
      return stripHtml(this.editedTitle);
    },

    pdfViewerAvailable() {
      return (!this.isIE11)
        && !this.pdfMissing
        && this.document.pdfInfo
        && this.document.pdfInfo.pdfSourceUrl;
    },

    voteResultsPresent() {
      return !!this.document?.related?.voteResults?.fullResult;
    },

    voteResultsDate() {
      return this.document?.related?.voteResults?.resultDate;
    },
  },

  methods: {
    formatDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    toggle() {
      this.toggleShowPDF(!this.isActivePdf);
    },

    reemit(...args) {
      this.$emit('pdfPageReady', ...args);
    },

    documentUrl() {
      // Convert the europarl link to https because chrome won't download document over http.
      if (/http:\/\/(www\.)?europarl\.europa\.eu/.test(this.document.url)) {
        return this.document.url.replace('http://', 'https://');
      }

      const { related } = this.document;
      if (
        ['PC', 'CC'].includes(this.document.type.split('-')[0])
        && (related && related.dossier && related.dossier.url)
      ) return related.dossier.url;

      return this.document.url;
    },

    trackOpenSource(url) {
      this.$gtag.event('document-source-external', {
        event_category: 'document',
        event_label: url,
      });
    },

    getDocumentTypeLocalization(documentType) {
      const loc = this.getLocalizationForDocTypeId(
        documentType,
        { locale: this.userPreferences.locale },
      );
      return typeof loc === 'string' ? loc : loc.singular;
    },

    toggleShowPDF(value) {
      this.isActivePdf = !this.isActivePdf;
      if (this.pdfExpandedView) this.$emit('togglePdfExpandedView');
      this.$emit('toggleShowPDF', value);
      if (!value) {
        EventBus.$off('switchToTextView');
      }
    },

    getPdfSourceUrl() {
      const { pdfInfo } = this.document;
      if (!pdfInfo) return null;
      const { pdfSourceUrl } = pdfInfo;
      return pdfSourceUrl;
    },

    initSwitchToDocumentView() {
      if (this.isActivePdf && this.getPdfSourceUrl()) {
        showDialog(DocumentSwitchToTextView);
        EventBus.$on('switchToTextView', this.toggleShowPDF);
      }
    },

    playAudioChunk(ts) {
      this.audioPlayer.currentTime = ts;
      this.audioPlayer.play();
    },
  },
};
</script>

<style scoped lang="scss">
  .v-subheader {
    height: auto;
  }
  .subTitlePart {
    margin-right: 1em;
  }

  .options {
    display: flex;
    flex-wrap: wrap;

    .pdfToggle {
      display: flex;
      align-items: center;

      font-size: 0.8em;
      margin: 0 1em;

      padding-right: 1.5em;
      border-right: thin solid #9e9e9e;

      span {
        font-weight: 500;

        &.txt {
          margin-right: 0.5em;
        }
      }

      .pdfSwitch {
        margin: 0;
        padding: 0;

        ::v-deep {
          .v-input__slot {
            margin-bottom: 0;
          }
          .v-messages {
            display: none;
          }
        }
      }
    }
  }
  span {
    display: inline-block;
    cursor: pointer;
  }

  .pre-header {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .docType {
      font-size: 1.10rem;
      min-width: 150px;
      display: flex;

      .aiIcon {
        margin-left: 0.5em;
        width: 26px;
        height: 26px;

        path {
          fill: teal;
        }
      }
    }
  }
</style>
